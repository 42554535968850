import React from "react";
import Iframe from "react-iframe";
import { Button } from 'semantic-ui-react'
import "./App.css";

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="App-div">
          <h1 className="App-title">Baldoni & Asociados</h1>
          <a
            className="App-mail"
      
          >
            ABOGADOS
          </a>
          <div className="separador"></div>
          <hr class="solid"></hr>
          <p className="datos">
            Av. San Martín N° 302, Bernal (CP 1876), Buenos Aires, Argentina
          </p>
          <p className="datos">
            <a className="datos" href="tel://+541142523902">
            <i class="phone icon"></i>:(+54 11) 4252-3902
            </a>{" "}
            </p>
    
          <a className="datos" href="tel://+5491155243207">
          <i class="whatsapp icon"></i>: (+54 11) 5524-3207
            </a>{" "}
          <p>
            {" "}
            <a className="datos" href="mailto:estudio@baldoniyasociados.com.ar">
            <i class="envelope outline icon"></i> 
            estudio@baldoniyasociados.com.ar
            </a>
          </p>
          <hr class="solid"></hr>
          <h2 className="App-subtitle">Contacto</h2>
         
        </div>
        <div className="separador2"></div>
        <div className="button-container">
        <Button circular  href="tel:5491169305657"size='massive' color='blue' icon='phone' />
        <Button circular  href="mailto:estudio@baldoniyasociados.com.ar" size='massive' color='google plus' icon='envelope outline' />
        <Button circular  href="https://api.whatsapp.com/send?phone=+5491155243207&text=Quiero%20consultar%20sobre" size='massive' color='green' icon='whatsapp' />
        
        </div>
        <div class="google-maps">
            <Iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3279.7520212196378!2d-58.27883118482358!3d-34.711434080431786!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a32e013312397b%3A0x3a83549732558fb7!2sAv.%20San%20Mart%C3%ADn%20302%2C%20Bernal%2C%20Provincia%20de%20Buenos%20Aires!5e0!3m2!1ses-419!2sar!4v1603379342646!5m2!1ses-419!2sar"
              width="1000"
              height="450"
              allowfullscreen=""
              aria-hidden="false"
              tabindex="0"
            ></Iframe>
          </div>
      </header>
    </div>
  );
}

export default App;
